<template>
  <div class="parent">
    <v-container class="newsletter-container py-16 px-5 px-md-0">
      <v-row>
        <v-col cols="12" md="6">
          <div class="text-h5 text-md-h3 text-center text-md-right">
            Special Events Newsletter
          </div>
          <div
            class="text-body-2 text-md-body-1 mt-2 text-center text-md-right"
          >
            Sign up to learn about special events such as Retreats, VBS, and
            choir programs.
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <MailChimpSignup :outlined="true" :dark="true"></MailChimpSignup>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import MailChimpSignup from "./MailChimpSignup.vue";

export default {
  name: "NewsletterSection",
  components: {
    MailChimpSignup,
  },
  data: () => ({}),
};
</script>
<style lang="scss" scoped>
.parent {
  background: linear-gradient(
    -45deg,
    #c62828,
    #6a1b9a,
    // center
    #1565c0,
    #6a1b9a,
    #c62828
  );

  // background: linear-gradient(
  //   -45deg,
  //   #b80083,
  //   #b800b1,
  //   #9100b8,
  //   #6300b8,
  //   #3500b8,
  //   // center
  //   #0700b8,
  //   #3500b8,
  //   #6300b8,
  //   #9100b8,
  //   #b800b1,
  //   #b80083
  // );
  // background: linear-gradient(-45deg, #0700b8, #00a357, #0700b8, #00a357);
  // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}
.newsletter-container {
  color: white;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
