<template>
  <div class="parent">
    <v-img
      :src="srcComputed"
      :lazy-src="lazySrc"
      :alt="alt"
      width="100%"
      ref="image"
      :contain="false"
      :aspect-ratio="aspectRatio"
      :class="imgClass"
    />
  </div>
</template>

<script>
export default {
  props: {
    displayed: { type: Boolean, default: false },
    src: { type: String, default: "" },
    lazySrc: { type: String, default: "" },
    srcMobile: { type: String, default: "" },
    alt: { type: String, default: "" },
    imgClass: { type: String, default: "" },
  },
  name: "PictureSlide",
  computed: {
    srcComputed() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.srcMobile;
        case "sm":
          return this.srcMobile;
        case "md":
          return this.src;
        case "lg":
          return this.src;
        case "xl":
          return this.src;
      }
      return this.src;
    },
    aspectRatio() {
      // xs images should always be 2:3, other images should be 16:9
      return this.$vuetify.breakpoint.name == "xs" ? 2 / 3 : 16 / 9;
    },
  },
  // mounted() {
  //   this.$refs.image.style.filter = this.filter;
  // },
};
</script>

<style scoped>
.parent {
  width: 100vw;
  z-index: default;
}

/* Without this, there will be an empty line below the image https://stackoverflow.com/questions/6664460/line-height-affects-images?noredirect=1&lq=1 */
img {
  display: block;
}
</style>
