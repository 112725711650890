import Vue from "vue";
import App from "./App.vue";
import VueYoutube from "vue-youtube";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

Vue.use(VueYoutube);

Vue.config.productionTip = false;

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    let suffix = "Apostolic Christian Church of North Phoenix";
    let separator = " • ";
    let title = "Apostolic Christian Church of North Phoenix";
    if (to.meta.title) {
      title = to.meta.title + separator + suffix;
    }
    document.title = title;
  });
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
