<template>
  <v-container ref="parent" class="my-16 parent">
    <v-row dense justify="center">
      <v-col class="text-center top-layer" cols="4" sm="3" md="3" lg="2">
        <div class="text-h6 text-sm-h5 text-md-h4 font-weight-bold">
          Apostolic
        </div>
        <div class="text-subtitle-2 font-weight-light text--secondary">
          ἀπόστολος<span class="d-none d-sm-inline"
            ><v-icon>{{ mdiSquareSmall }}</v-icon
            >apostle</span
          >
        </div>
        <div class="text-body-2 text-sm-body-1 font-weight-medium">
          One who is
          <span
            ref="apostolicFrom"
            class="
              blue
              darken-2
              white--text
              px-1
              rounded
              no-wrap
              custom-shadow-7
            "
            >sent out</span
          >
        </div>
      </v-col>
      <v-col class="text-center top-layer" cols="4" sm="3" md="3" lg="2">
        <div class="text-h6 text-sm-h5 text-md-h4 font-weight-bold">
          Christian
        </div>
        <div class="text-subtitle-2 font-weight-light text--secondary">
          Χριστιανός<span class="d-none d-sm-inline"
            ><v-icon>{{ mdiSquareSmall }}</v-icon
            >christian</span
          >
        </div>
        <div class="text-body-2 text-sm-body-1 font-weight-medium">
          <span
            class="purple white--text px-1 rounded custom-shadow-7"
            ref="christianFrom"
            >Follower of Christ</span
          >
        </div>
      </v-col>
      <v-col class="text-center top-layer" cols="4" sm="3" md="3" lg="2">
        <div class="text-h6 text-sm-h5 text-md-h4 font-weight-bold">Church</div>
        <div class="text-subtitle-2 font-weight-light text--secondary">
          ἐκκλησία<span class="d-none d-sm-inline"
            ><v-icon>{{ mdiSquareSmall }}</v-icon
            >church</span
          >
        </div>
        <div class="text-body-2 text-sm-body-1 font-weight-medium">
          <span
            class="red darken-2 white--text px-1 rounded custom-shadow-7"
            @mouseover="hoverChurch = true"
            ref="churchFrom"
            >Community</span
          >
          or congregation
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-10 mx-1 mx-sm-2">
      <v-col style="z-index: 1">
        <v-sheet
          outlined
          class="pa-3 rounded-lg transparent-background font-weight-bold"
        >
          The
          <span class="font-weight-black">Apostolic Christian Church</span> is a
          <span
            class="
              red
              darken-2
              white--text
              px-1
              font-weight-black
              rounded
              custom-shadow-7
            "
            ref="churchTo"
            >community</span
          >
          of
          <span
            class="
              purple
              white--text
              px-1
              font-weight-black
              rounded
              no-wrap
              custom-shadow-7
            "
            ref="christianTo"
            >followers of Christ</span
          >
          who have been
          <span
            class="
              blue
              darken-2
              white--text
              px-1
              font-weight-black
              rounded
              no-wrap
              custom-shadow-7
            "
            ref="apostolicTo"
            >sent out</span
          >
          to preach the Gospel to Phoenix, and ultimately, the world.
        </v-sheet>
      </v-col>
    </v-row>
    <div
      class="lines-parent left-to-right"
      ref="leftToRight"
      style="display: none"
    >
      <div class="top border"></div>
      <div class="bottom border"></div>
    </div>
    <div
      class="lines-parent right-to-left"
      ref="rightToLeft"
      style="display: none"
    >
      <div class="top border"></div>
      <div class="bottom border"></div>
    </div>
  </v-container>
</template>
<script>
import { mdiSquareSmall } from "@mdi/js";

export default {
  name: "ACCSection",
  data: () => ({
    mdiSquareSmall,
    names: ["apostolic", "christian", "church"],
    hoverChurch: false,
    hoverApostolic: false,
    hoverChristian: false,
  }),
  mounted() {
    this.names.forEach((n) => this.createLineElements(n));
    this.names.forEach((n, i) => {
      this.calcPosition(n, i);
      window.addEventListener("resize", () => this.calcPosition(n, i));
    });
  },
  destroyed() {
    this.names.forEach((n, i) => {
      window.removeEventListener("resize", () => this.calcPosition(n, i));
    });
  },
  methods: {
    createLineElements(name) {
      let leftToRight = this.$refs.leftToRight.cloneNode(true);
      let rightToLeft = this.$refs.rightToLeft.cloneNode(true);
      leftToRight.classList.add(name);
      rightToLeft.classList.add(name);
      this.$refs.parent.appendChild(leftToRight);
      this.$refs.parent.appendChild(rightToLeft);
    },
    calcPosition(name, i) {
      let to = this.$refs[name + "To"];
      let from = this.$refs[name + "From"];

      let fromX = from.offsetLeft + from.getBoundingClientRect().width / 2;
      let fromY = from.offsetTop + from.getBoundingClientRect().height;

      let toX = to.offsetLeft + to.getBoundingClientRect().width / 2;
      let toY = to.offsetTop;

      let container, width;

      if (fromX < toX) {
        container = this.$refs.parent.querySelector(
          ".lines-parent.left-to-right." + name
        );
        container.style.left = fromX + "px";
        container.style.display = "flex";
        width = toX - fromX;
        this.$refs.parent.querySelector(
          ".lines-parent.right-to-left." + name
        ).style.display = "none";
      } else {
        container = this.$refs.parent.querySelector(
          ".lines-parent.right-to-left." + name
        );
        container.style.left = toX + "px";
        container.style.display = "flex";
        width = fromX - toX;
        this.$refs.parent.querySelector(
          ".lines-parent.left-to-right." + name
        ).style.display = "none";
      }

      let height = toY - fromY + 2;

      container.style.top = fromY + "px";
      container.style.width = width + "px";
      container.style.height = height + "px";
      container.querySelector(".top").style.minHeight =
        13 * (i + 1) -
        6 -
        (from.parentNode.getBoundingClientRect().top -
          container.getBoundingClientRect().top) +
        "px";
    },
  },
};
</script>
<style lang="scss" scoped>
.parent {
  position: relative;
}
.top-layer {
  z-index: 1;
}
.transparent-background {
  background-color: rgba(255, 255, 255, 0);
}
.no-wrap {
  white-space: nowrap;
}
.lines-parent {
  position: absolute;
  display: flex;
  flex-flow: column;
  z-index: 0;
}
.border {
  border-radius: 8px;
  border-style: solid;
  border-width: 2px;
  z-index: 0;
}

.apostolic {
  .border {
    border-color: #90caf9;
  }
}

.christian {
  .border {
    border-color: #ce93d8;
  }
}

.church {
  .border {
    border-color: #ef9a9a;
  }
}

.top {
  position: relative;
}
.bottom {
  position: relative;
  top: -2px;
  height: 100%;
}
.left-to-right .top {
  border-top: none;
  border-top-left-radius: 0px;
  border-right: none;
  margin-right: 6px;
  border-bottom-right-radius: 0px;
}
.left-to-right .bottom {
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: none;
  margin-left: 6px;
}
.right-to-left .top {
  border-top: none;
  border-top-right-radius: 0px;
  border-left: none;
  margin-left: 6px;
  border-bottom-left-radius: 0px;
}
.right-to-left .bottom {
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom: none;
  margin-right: 6px;
}
</style>
