<template>
  <v-responsive :aspect-ratio="aspectRatio" class="overlay-parent">
    <v-container fill-height fluid width="100%">
      <v-row align="center" justify="center">
        <v-col class="centered-content">
          <div
            class="white--text pa-8 pa-xs-4 custom-shadow-0"
            :class="headingClass"
          >
            <span class="font-weight-light">Your home for </span
            ><span class="font-weight-bold">Biblical Truth </span>
            <span class="font-weight-light">& </span>
            <span class="font-weight-bold">Community </span>
          </div>
          <div class="clickable-content">
            <v-btn
              class="ma-2"
              href="https://maps.google.com/?q=ACC%20North%20Phoenix%2015651%20N%2023rd%20Ave,%20Phoenix,%20AZ%2085023"
              target="_blank"
              :elevation="5"
              x-large
            >
              Directions
            </v-btn>
            <v-btn
              class="ma-2"
              :elevation="5"
              x-large
              :to="{ name: 'Schedule' }"
              >Schedule</v-btn
            >
            <v-btn class="ma-2" :elevation="5" x-large :to="{ name: 'Contact' }"
              >Ask For Prayer</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
export default {
  name: "SlideShowOverlay",
  data: function () {
    return {};
  },
  computed: {
    aspectRatio() {
      // xs images should always be 2:3, other images should be 16:9
      return this.$vuetify.breakpoint.name == "xs" ? 2 / 3 : 16 / 9;
    },
    headingClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-h4";
        case "sm":
          return "text-h4";
        case "md":
          return "text-h3";
        default:
          return "text-h2";
      }
    },
  },
};
</script>

<style scoped>
.overlay-parent {
  position: absolute;
  z-index: 1;
  width: 100%;
  pointer-events: none;
}
.centered-content {
  text-align: center;
}
.clickable-content {
  pointer-events: auto;
}
</style>
