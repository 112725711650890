<template>
  <EventBase
    :max-height="null"
    :height="null"
    :min-height="null"
    class="black-background"
  >
    <div class="parent">
      <v-img
        :src="bannerDesktop"
        :lazy-src="bannerDesktopLazy"
        max-width="1400px"
        width="100vw"
        contain
        alt="The Southwest Sing will be hosted at the North Phoenix Church, February 15th - 16th."
        v-if="!['xs'].includes($vuetify.breakpoint.name)"
      >
        <div class="button-container">
          <v-btn
            class="button"
            outlined
            dark
            href="https://docs.google.com/forms/d/e/1FAIpQLSczIh8M_q3pHjpeDEoT1rdt_G5tJtNvOUKxOK6UWXnztPGCXA/viewform"
            target="_blank"
            x-large
            >Please RSVP Here</v-btn
          >
        </div>
      </v-img>
      <v-img
        :src="bannerMobile"
        :lazy-src="bannerMobileLazy"
        width="100vw"
        max-height="90vh"
        contain
        alt="The Southwest Sing will be hosted at the North Phoenix Church, February 15th - 16th."
        v-else
      >
        <div class="button-container">
          <v-btn
            class="button"
            outlined
            dark
            href="https://docs.google.com/forms/d/e/1FAIpQLSczIh8M_q3pHjpeDEoT1rdt_G5tJtNvOUKxOK6UWXnztPGCXA/viewform"
            target="_blank"
            x-large
            >Please RSVP Here</v-btn
          >
        </div>
      </v-img>
    </div>
  </EventBase>
</template>
<script>
import EventBase from "../EventBase.vue";
import bannerDesktop from "./sw-sing-2025-banner.png";
import bannerDesktopLazy from "./sw-sing-2025-banner.png";
import bannerMobile from "./sw-sing-2025-banner-mobile.png";
import bannerMobileLazy from "./sw-sing-2025-banner-mobile.png";
export default {
  name: "SWSing2025",
  components: {
    EventBase,
  },
  data: () => ({
    bannerDesktop,
    bannerDesktopLazy,
    bannerMobile,
    bannerMobileLazy,
  }),
};
</script>
<style lang="scss" scoped>
.parent {
  position: relative;
}

.button-container {
  position: absolute;
  bottom: 32px;
  z-index: 1;
  width: 100%;
  text-align: center;
}
</style>
