<template>
  <div>
    <PageHeadingFullScreen
      :src="headerImage"
      :srcMobile="headerImageMobile"
      :srcLazy="headerImageLazy"
      title="Statement of Faith"
      filter="brightness(70%) contrast(125%)"
      dense
    ></PageHeadingFullScreen>
    <v-container class="mt-3">
      <v-row>
        <v-col cols="12" v-for="(statement, i) in weBelieve" :key="i">
          <v-card>
            <v-card-text>
              <!-- <p class="text-h6 text--primary">We Believe</p> -->
              <div class="text--primary">
                <span class="mr-1 font-weight-thin">{{ i + 1 }}.</span> WE
                BELIEVE
                {{ statement.statement }}
              </div>
            </v-card-text>
            <div class="px-3 pb-3">
              <v-chip
                small
                link
                outlined
                v-for="passage in statement.passages"
                :key="passage"
                class="ma-1"
                :href="generateBibleLink(passage)"
                target="_blank"
                >{{ passage }}</v-chip
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
      <div class="mb-6"></div>
    </v-container>
  </div>
</template>

<script>
import PageHeadingFullScreen from "../components/PageHeadingFullScreen.vue";

import headerImage from "./DSC00532-cropped-large.webp";
import headerImageMobile from "./DSC00532-cropped-mobile.webp";
import headerImageLazy from "./DSC00532-cropped-lazy.webp";

export default {
  name: "StatementOfFaith",
  components: {
    PageHeadingFullScreen,
  },
  data() {
    return {
      headerImage,
      headerImageMobile,
      headerImageLazy,
      weBelieve: [
        {
          statement:
            "that the Bible has been inspired by God, and is the complete and infallible Word of God.",
          passages: [
            "II Timothy 3:16",
            "II Peter 1:20-21",
            "Revelation 22:18-19",
          ],
        },
        {
          statement:
            "that there is one God, without beginning and without ending.",
          passages: ["Revelation 1:8", "Micah 5:2"],
        },
        {
          statement: "that God is a trinity - Father, Son, and Holy Spirit.",
          passages: ["Matthew 28:19", "II Corinthians 13:14"],
        },
        {
          statement:
            "that Jesus Christ is God incarnate. He was born of a virgin. He lived a sinless life. He died on the cross in the place of the sinner, and His shed blood is the atonement for our sins.",
          passages: [
            "John 20:28",
            "Isaiah 9:6-7",
            "Hebrews 1:1-8",
            "Isaiah 7:14",
            "Matthew 1:23",
            "Hebrews 7:26",
            "I John 2:2",
            "II Corinthians 5:21",
            "Ephesians 2:13-20",
            "John 8:46",
          ],
        },
        {
          statement:
            "in His bodily resurrection, and His ascension to the right hand of God, and that He will make a personal return to earth in power and glory.",
          passages: ["I Corinthians 15:4-8", "Matthew 25:31", "Acts 1:9-11"],
        },
        {
          statement:
            "that all are sinners and lost, awaiting the judgment of God, but God is not willing that any should be lost.",
          passages: ["Romans 3:10-23", "II Peter 3:7-9", "Romans 11:32"],
        },
        {
          statement:
            "that the sinner may have salvation by repentance toward God and faith in Jesus Christ.",
          passages: [
            "Acts 2:38",
            "Acts 20:21",
            "Romans 10:8-13",
            "Hebrews 7:25",
          ],
        },
        {
          statement:
            "that a saving faith will result in regeneration by the Holy Spirit and the Word of God without which there is no salvation.",
          passages: [
            "John 1:12-13",
            "John 3:6-8",
            "John 3:16-17",
            "Ephesians 5:26-27",
            "I Peter 1:22-23",
          ],
        },
        {
          statement:
            "in the baptism of the Holy Spirit, by Whose indwelling the believer is empowered to live a Godly life in a spiritual unity with Christ.",
          passages: [
            "John 1:33",
            "Acts 8:16-17",
            "Romans 8:2-17",
            "John 14:23",
            "I Corinthians 3:16",
            "I Corinthians 12:13",
            "I John 2:6",
          ],
        },
        {
          statement:
            "that both the saved and the lost will be resurrected; the saved unto life eternal and the lost unto eternal damnation.",
          passages: ["John 5:28-29", "I Corinthians 15:51-58"],
        },
        {
          statement:
            'that under any and all circumstances, we should speak the truth, and should not swear - obeying the commandment of Christ: "Swear not at all; but let your communication be Yea, Yea; and Nay, Nay."',
          passages: ["Matthew 5:34-37", "James 5:12"],
        },
        {
          statement:
            'that government is ordained of God to enforce law and order, and to prevent evil works. Government should, therefore, be supported and obeyed. All laws and ordinances are to be respected and conscientiously obeyed, except when to do so violates the commandment of God. The call to military service likewise is to be respected and obeyed, but with the limitation to noncombatant service. As taught by Jesus Christ: "Love your enemies, bless them that curse you, do good to them that hate you, and pray for them which despitefully use you and persecute you."',
          passages: ["​Matthew 5:44", "Romans 13:1-10", "I Peter 2:13-15"],
        },
        {
          statement:
            "that God now commands all men to repent and be baptized, and that immersion is the scriptural mode of water baptism. But WE BELIEVE, that baptism should be administered to those alone who have believed on Christ, and have been converted by Him, who have indeed died to sin, and have experienced a spiritual rebirth.",
          passages: [
            "Matthew 28:19-20",
            "John 3:3",
            "Acts 2:37-38",
            "Acts 26:20",
            "Romans 6:1-13",
            "Acts 3:19",
            "II Corinthians 5:17",
          ],
        },

        {
          statement:
            "in the exercise of discipline by the church in order to deal with the presence of sin in the lives of its members.",
          passages: [
            "I Corinthians 5:1-13",
            "II Corinthians 2:5-11",
            "II Corinthians 5:14-18",
            "II Thessalonians 3:6",
            "II Thessalonians 3:14-15",
            "Matthew 18:15-17",
            "Hebrews 10:24-26",
            "Galatians 6:1-2",
            "Titus 3:10-11",
            "I Timothy 5:20",
          ],
        },
        {
          statement:
            'that the Great Commission of our Lord Jesus Christ is an invitation for everyone to live a life of abandonment to Him Who loved us and gave Himself for us - surrendering his personal ambitions and possessions in an unreserved committal to take the proclamation of the Gospel throughout "all the world to every creature."  ',
          passages: [
            "Matthew 28:19-20",
            "Acts 1:8",
            "Mark 16:15",
            "Luke 24:47",
            "II Corinthians 5:18",
            "Luke 14:26-27",
            "Luke 14:33",
            "Luke 5:27-28",
          ],
        },
        {
          statement:
            'that the gift of eternal life is a present possession of every true disciple of Jesus Christ, and that nothing nor anyone can take it from him; but that it is a realistic possibility, however, for a true believer, once saved, of his own free will to no longer "continue in the faith" - but instead to apostatize, "depart from the faith" return to sin, and consequently forfeit the eternal life he once possessed.',
          passages: [
            "I John 5:11-13",
            "Romans 8:35-39",
            "Hebrews 3:6",
            "Hebrews 3:12-14",
            "Romans 6:16",
            "II Peter 2:1-22",
            "Matthew 25:24",
            "Matthew 25:30",
            "I Corinthians 9:27",
            "I Corinthians 10:1-12",
            "I Timothy 1:18-20",
            "I Timothy 4:1-3",
            "Revelation 2:10",
            "Revelation 3:5",
            "John 3:15-18",
            "John 15:1-7",
          ],
        },
        {
          statement:
            'that "the bread and fruit of the vine" in Holy Communion symbolize the body and blood of Christ. Communion is served to members of the fellowship only, following self-examination.',
          passages: [
            "I Corinthians 10:16",
            "I Corinthians 10:21",
            "I Corinthians 11:17-32",
            "Luke 22:7-20",
          ],
        },
        {
          statement:
            "that a veil or head covering should be worn by Sisters in the Lord during prayer and worship, as a symbol of their submission according to God's order of creation.",
          passages: ["​I Corinthians 11:1-16"],
        },
        {
          statement:
            "that marriage was instituted by God at the beginning of creation and, as such, is an exclusive, lifelong union between one man and one woman, as He created them.",
          passages: [
            "Genesis 1:27-28a",
            "Genesis 2:18",
            "Genesis 2:21-24",
            "Matthew 19:4-6",
            "Mark 10:6-9",
          ],
        },
      ],
    };
  },
  methods: {
    generateBibleLink(passage) {
      passage = encodeURI(passage);
      return `https://www.biblegateway.com/passage/?search=${passage}&version=KJV;NLT`;
    },
  },
};
</script>

<style scoped></style>
