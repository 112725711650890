import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    youtubeVideos: [],
  },
  getters: {
    youtubeVideos: (state) => state.youtubeVideos,
  },
  mutations: {
    SET_YOUTUBE_VIDEOS(state, youtubeVideos) {
      state.youtubeVideos = youtubeVideos;
    },
  },
  actions: {
    getYoutubeVides() {
      const youtubeFeedUrl =
        "https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCh9sOrUGpzRDFtdz0lwljXA&maxResults=10&order=date&key=AIzaSyCw_h8bCnvfeAhgEhcLAvJPXTe2BJJdNgs&eventType=completed&type=video";
      return new Promise((resolve, reject) => {
        fetch(youtubeFeedUrl)
          .then((r) => r.json())
          .then((youtubeVideos) => {
            this.commit("SET_YOUTUBE_VIDEOS", youtubeVideos.items);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
});
