<!--

Export 3 Images:

- full size
  size 1920x1080
  compression 60%

- mobile
  size 960x540
  compression 50%

- lazy
  size 480x270
  compression 10%

 -->

<template>
  <div class="parent mt-n16 mt-md-n13 mt-lg-0" ref="parent">
    <div class="overlay">
      <div
        :class="titleStyle"
        style="text-shadow: 2px 2px 9px rgba(0, 0, 0, 0.5)"
      >
        {{ title }}
      </div>
    </div>
    <div class="triangle-overlay-parent">
      <div class="triangle-overlay"></div>
    </div>
    <v-img
      :src="srcComputed"
      :lazy-src="lazySrcComputed"
      :alt="alt"
      width="100%"
      height="280"
    ></v-img>
  </div>
</template>
<script>
export default {
  name: "PageHeadingFullScreen",
  props: {
    title: { type: String, default: "" },
    filter: { type: String, default: "" },
    alt: { type: String, default: "" },
    src: { type: String, default: "" },
    lazySrc: { type: String, default: "" },
    srcMobile: { type: String, default: "" },
    dense: { type: Boolean, default: false },
  },
  data: () => ({}),
  computed: {
    titleStyle() {
      let common = "";
      if (this.dense) {
        common =
          common + (this.$vuetify.breakpoint.name == "xs")
            ? " font-weight-light "
            : " font-weight-thin ";
        return (
          common +
          " text-h4 text-sm-h3 text-md-h2 mt-lg-0 text-lg-h1 mt-md-3 mt-sm-5 mt-7"
        );
      } else {
        return common + " text-h2 text-md-h1 font-weight-thin mt-md-0 mt-7";
      }
    },
    srcComputed() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.srcMobileComputed;
        case "sm":
          return this.srcMobileComputed;
        case "md":
          return this.src;
        case "lg":
          return this.src;
        case "xl":
          return this.src;
      }
      return this.src;
    },
    srcMobileComputed() {
      return this.srcMobile ? this.srcMobile : this.src;
    },
    lazySrcComputed() {
      return this.lazySrc != "" ? this.lazySrc : this.srcMobileComputed;
    },
  },
  mounted() {
    this.$refs.parent.querySelector(".v-image").style.filter = this.filter;
  },
};
</script>
<style lang="scss" scoped>
.parent {
  position: relative;
  width: 100vw;
  z-index: default;
  pointer-events: none;
}
.overlay {
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  z-index: 2;
  color: white;
  top: 130px;
}

img {
  display: block;
}

.triangle-overlay-parent {
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  z-index: 1;
  top: 242px;
}

.triangle-overlay {
  width: 0;
  height: 0;
  margin: auto;
  border-style: solid;
  border-width: 10px 18px 0 18px;
  border-color: white transparent transparent transparent;
  opacity: 1;
  text-align: center;
}
</style>
