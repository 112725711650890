<template>
  <div class="parent">
    <video
      :title="alt"
      playsinline
      webkit-playsinline
      muted
      loop
      class="video"
      ref="video"
      preload
    >
      <source :src="src" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  name: "VideoSlide",
  props: {
    displayed: { type: Boolean, default: false },
    src: { type: String, default: "" },
    alt: { type: String, default: "" },
    filter: { type: String, default: "brightness(0.85)" },
  },
  mounted() {
    this.$refs.video.style.filter = this.filter;
  },
  watch: {
    displayed() {
      if (this.displayed) {
        this.$refs.video.currentTime = 0;
        this.$refs.video.play();
      }
    },
  },
};
</script>

<style scoped>
.video {
  width: 100%;
}
.parent {
  width: 100vw;
  z-index: default;
}

/* Without this, there will be an empty line below the image https://stackoverflow.com/questions/6664460/line-height-affects-images?noredirect=1&lq=1 */
video {
  display: block;
}
</style>
