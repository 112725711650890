<template>
  <div>
    <div class="unique-parent gradient mb-5">
      <div
        class="
          heading
          my-15
          px-5
          my-md-14
          px-md-5
          text-h4 text-sm-h3 text-md-h2 text-lg-h1 text-center
          font-weight-light
          custom-shadow-0
        "
      >
        What Makes Us Unique?
        <div class="text-body-1 mt-4 font-weight-medium text-center">
          These are practices to which we give special emphasis.
        </div>
        <div class="triangle-overlay-parent">
          <div class="triangle-overlay"></div>
        </div>
      </div>
    </div>
    <v-row class="pb-5 mx-3">
      <v-col v-for="(item, i) in items" :key="i" cols="12" sm="6" lg="3">
        <v-sheet class="item-container" rounded>
          <div class="text-container pa-6 pa-md-15 pa-lg-6 pa-xl-15">
            <div
              class="
                item-text
                text-h5 text-sm-h4 text-xl-h3
                font-weight-light
                custom-shadow-0
              "
            >
              {{ item.title }}
              <div class="text-body-1 mt-2">
                {{ item.desc }}
              </div>
            </div>
          </div>
          <v-img
            :src="item.src"
            :lazy-src="item.lazySrc"
            class="item-image"
            :class="item.filterClass"
          ></v-img>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DSC00552 from "./DSC00552-cropped-compressed.webp";
import DSC00552_lazy from "./DSC00552-cropped-compressed-lazy.webp";

import DSC00579 from "./DSC00579-cropped-compressed.webp";
import DSC00579_lazy from "./DSC00579-cropped-compressed-lazy.webp";

import layLed from "./_MG_4974-copped-compressed.webp";
import layLedLazy from "./_MG_4974-copped-compressed-lazy.webp";

import missionsPicture from "./larm-rmah-AEaTUnvneik-unsplash-cropped-compressed.webp";
import missionsPicture_lazy from "./larm-rmah-AEaTUnvneik-unsplash-cropped-compressed-lazy.webp";

export default {
  name: "WhatMakesUsUnique",
  data: () => ({
    items: [
      {
        title: "Corporate Worship",
        desc: "We enjoy gathering together to worship God as a church through song.",
        src: DSC00552,
        lazySrc: DSC00552_lazy,
        filterClass: "custom-darken-9",
      },
      {
        title: "Church Lunch",
        desc: "We enjoy fellowship. Every Sunday, after the morning service, we enjoy a meal together & you are invited to join us.",
        src: DSC00579,
        lazySrc: DSC00579_lazy,
        filterClass: "custom-darken-6",
      },
      {
        title: "Lay Led",
        desc: "Our church is led & run entirely by volunteers.",
        src: layLed,
        lazySrc: layLedLazy,
        filterClass: "custom-darken-7",
      },
      {
        title: "Missions",
        desc: "We particiapate in local & international missions by both giving and going.",
        src: missionsPicture,
        lazySrc: missionsPicture_lazy,
        filterClass: "custom-darken-8",
      },
    ],
  }),
};
</script>
<style lang="scss" scoped>
.unique-parent {
  position: relative;
  width: 100%;
  height: 250px;
}
.heading {
  position: absolute;
  color: white;
  width: 100%;
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.9);
  z-index: 1;
}

.item-image {
  border-radius: 8px;
  overflow: hidden;
}
.item-container {
  position: relative;
}
.text-container {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-text {
  width: 100%;
  text-align: center;
  color: white;
}
.triangle-overlay-parent {
  width: 100%;
  height: 100%;
  text-align: center;
  margin-top: 20px;
  z-index: 1;
  top: 0px;
}

.triangle-overlay {
  width: 0;
  height: 0;
  margin: auto;
  border-style: solid;
  border-width: 15px 25px 0 25px;
  border-color: white transparent transparent transparent;
  opacity: 1;
  text-align: center;
}

/* https://uigradients.com/#Mild */
.gradient {
  background: #41295a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #2f0743,
    #41295a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2f0743,
    #41295a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>
