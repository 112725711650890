<template>
  <div>
    <PageHeadingFullScreen
      :src="headerImage"
      :srcMobile="headerImageMobile"
      :srcLazy="headerImageLazy"
      title="Schedule & Calendar"
      filter="brightness(70%) contrast(95%)"
      dense
    ></PageHeadingFullScreen>
    <v-container>
      <div class="text-body-1 mt-5">
        All are welcome to attend any of our services. If you are coming for the
        first time, we suggest coming for our 11AM service on Sunday and staying
        for lunch, which is free for all visitors.
      </div>
      <div class="text-h5 mt-5 mb-3">Sunday</div>
      <v-timeline align-top dense>
        <v-timeline-item
          class="pt-1"
          small
          v-for="(e, i) in sundayEvents"
          :key="i"
        >
          <v-row dense>
            <v-col cols="5" sm="4" md="3" lg="2">
              {{ e.time }}
            </v-col>
            <v-col>
              <strong>{{ e.title }}</strong>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="0" sm="4" md="3" lg="2"></v-col>
            <v-col class="pt-0">
              <div class="text-caption">
                {{ e.desc }}
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>

      <div class="text-h5 mt-5 mb-3">Wednesday</div>
      <v-timeline align-top dense>
        <v-timeline-item
          class="pt-1"
          small
          v-for="(e, i) in wednesdayEvents"
          :key="i"
        >
          <v-row no-gutters>
            <v-col cols="5" sm="4" md="3" lg="2">
              {{ e.time }}
            </v-col>
            <v-col>
              <strong>{{ e.title }}</strong>
            </v-col>
          </v-row>
          <v-row dense>
            <div class="text-caption">
              {{ e.desc }}
            </div>
          </v-row>
        </v-timeline-item>
      </v-timeline>
      <div class="mt-5">
        Please feel free to
        <router-link :to="{ name: 'Contact' }" class="link"
          >contact us</router-link
        >
        if you have any questions.
      </div>
      <div class="mt-5"></div>
      <iframe
        v-if="['xs'].includes($vuetify.breakpoint.name)"
        src="https://calendar.google.com/calendar/u/0/embed?src=accnorthphoenix@gmail.com&ctz=America/Phoenix&pli=1&mode=AGENDA&showCalendars=0&showTitle=0&showTabs=0&showPrint=0"
        class="mobile-calendar"
        frameborder="0"
        scrolling="no"
      ></iframe>
      <iframe
        v-else
        src="https://calendar.google.com/calendar/u/0/embed?src=accnorthphoenix@gmail.com&ctz=America/Phoenix&pli=1&mode=MONTH&showCalendars=0&showTitle=0&showTabs=1&showPrint=1"
        class="calendar"
        frameborder="0"
        scrolling="no"
      ></iframe>
    </v-container>
  </div>
</template>

<script>
import PageHeadingFullScreen from "../components/PageHeadingFullScreen.vue";
import headerImage from "./cropped-rylan-hill-pf-eZzTvVzk-unsplash-compressed-large.webp";
import headerImageMobile from "./cropped-rylan-hill-pf-eZzTvVzk-unsplash-compressed-mobile.webp";
import headerImageLazy from "./cropped-rylan-hill-pf-eZzTvVzk-unsplash-compressed-lazy.webp";

export default {
  name: "Schedule",
  components: {
    PageHeadingFullScreen,
  },
  data() {
    return {
      headerImage,
      headerImageMobile,
      headerImageLazy,
      sundayEvents: [
        {
          time: "10:00am - 10:45am",
          title: "Bible Study & Sunday School",
          desc: "Bible Study (adults) is in the Fellowship Hall; For kids, Sunday School is taught in their individual classrooms.",
        },
        {
          time: "11:00am - 12:00pm",
          title: "Morning Service",
          desc: "Singing followed by a sermon from one of the ministers. Child care provided for ages six and younger.",
        },
        {
          time: "12:00pm - 1:00pm",
          title: "Lunch",
          desc: "Lunch, served in the Fellowship Hall, is free for all visitors.",
        },
        {
          time: "1:00pm - 1:30pm",
          title: "Afternoon Service",
          desc: "Singing followed by a short topic presentation.",
        },
      ],
      wednesdayEvents: [
        {
          time: "7:30pm - 8:30pm",
          title: "Worship Service",
          desc: "Singing followed by a topic presentation.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.link {
  color: black;
  text-decoration: underline dotted grey;
}
.mobile-calendar {
  border-width: 0;
  height: 75vh;
  width: 100%;
}
.calendar {
  width: 100%;
  min-height: 768px;
  max-height: 75vh;
}
</style>
