<template>
  <div>
    <PageHeadingFullScreen
      :src="headerImage"
      :srcMobile="headerImageMobile"
      :srcLazy="headerImageLazy"
      title="Contact"
      filter="brightness(80%) contrast(120%)"
    ></PageHeadingFullScreen>
    <!-- https://docs.google.com/forms/d/1OxCSm8SwdVUiiZvYAcAFdypAp9b0A_twW2wKSBVpIbE/edit -->
    <v-container class="my-15">
      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-card
            class="mx-3"
            color="green lighten-3"
            href="https://docs.google.com/forms/d/e/1FAIpQLSeF3jzXn6GTJZSjbnwYL2gTZ171shnsCkYRpZj2X7dFOfA-eQ/viewform?usp=sf_link"
            target="_blank"
            hover
          >
            <v-card-title> Contact </v-card-title>
            <v-card-text>
              <p>
                Email us at:
                <a href="mailto:info@ACCNorthPhoenix.org" class="email-link"
                  >info@ACCNorthPhoenix.org</a
                >
              </p>
              <p>
                Our church is led and run by volunteers, so our response may not
                be immediate. We appreciate your patience & look forward to
                hearing from you!
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import headerImage from "./cropped-mariola-grobelska-XlmMpqFMBp0-unsplash-compressed-large.webp";
import headerImageMobile from "./cropped-mariola-grobelska-XlmMpqFMBp0-unsplash-compressed-mobile.webp";
import headerImageLazy from "./cropped-mariola-grobelska-XlmMpqFMBp0-unsplash-compressed-lazy.webp";

import PageHeadingFullScreen from "../components/PageHeadingFullScreen.vue";

export default {
  name: "Contact",
  components: {
    PageHeadingFullScreen,
  },
  data() {
    return {
      headerImage,
      headerImageMobile,
      headerImageLazy,
      src: "https://docs.google.com/forms/d/e/1FAIpQLSc8wBSOI1ROsIBnEZnuCZ_bHWbO9mlIGySXPO2EvHLXsS1E2w/viewform?embedded=true",
    };
  },
};
</script>

<style scoped></style>
