<template>
  <div>
    <PageHeadingFullScreen
      :src="headerImage"
      :srcMobile="headerImageMobile"
      :srcLazy="headerImageLazy"
      alt="Aerial photograph of the Phoenix area, where a mountain range meets the city, with a highway running through both."
      title="Location"
      filter="brightness(70%) contrast(130%)"
    ></PageHeadingFullScreen>
    <v-container>
      <Map class="mb-1" height="450px"></Map>
      <v-card color="#004c6e" dark class="mb-1">
        <v-row no-gutters align="center">
          <v-col cols="12" sm="6" class="d-flex">
            <div class="mx-auto py-10 py-sm-2 address-background">
              <v-card-subtitle class="text-h5">
                <div>ACC North Phoenix</div>
                <div>15651 N. 23rd Ave.</div>
                <div>Phoenix, AZ 85023</div>
                <v-btn
                  outlined
                  left
                  class="mt-3"
                  href="https://maps.google.com/?q=ACC%20North%20Phoenix%2015651%20N%2023rd%20Ave,%20Phoenix,%20AZ%2085023"
                  target="_blank"
                  block
                >
                  Directions
                </v-btn>
              </v-card-subtitle>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            order="first"
            order-sm="last"
            :class="videoParentClass"
          >
            <v-img
              src="./outside-mobile.webp"
              lazy-src="./outside-mobile-lazy.webp"
              v-if="['xs'].includes($vuetify.breakpoint.name)"
            ></v-img>
            <video
              playsinline
              webkit-playsinline
              v-else-if="['sm'].includes($vuetify.breakpoint.name)"
              src="./outside-2-270.mp4"
              autoplay="true"
              muted
              class="video"
            ></video>
            <video
              playsinline
              webkit-playsinline
              v-else
              src="./outside-2-480.mp4"
              autoplay="true"
              muted
              class="video"
            ></video>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Map from "../components/Map.vue";
import PageHeadingFullScreen from "../components/PageHeadingFullScreen.vue";
import headerImage from "./cropped-tyrel-johnson-qzOAhs1cYn4-unsplash-compressed-large.webp";
import headerImageMobile from "./cropped-tyrel-johnson-qzOAhs1cYn4-unsplash-compressed-small.webp";
import headerImageLazy from "./cropped-tyrel-johnson-qzOAhs1cYn4-unsplash-compressed-lazy.webp";

export default {
  name: "ChurchLocation",
  data() {
    return {
      headerImage,
      headerImageMobile,
      headerImageLazy,
    };
  },
  components: {
    Map,
    PageHeadingFullScreen,
  },
  computed: {
    videoParentClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "video-parent";
        case "sm":
          return "video-parent-sm";
        case "md":
          return "video-parent-sm";
        case "lg":
          return "video-parent-sm";
        case "xl":
          return "video-parent-sm";
      }
      return "video-parent";
    },
  },
};
</script>

<style scoped>
.address-background {
  background: url("./map-marker.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.video-parent {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
  line-height: 0px;
}
.video-parent-sm {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  line-height: 0px;
}
.video {
  width: 100%;
}
</style>
