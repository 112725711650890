<template>
  <div>
    <PageHeadingFullScreen
      :src="headerImage"
      :srcMobile="headerImageMobile"
      :srcLazy="headerImageLazy"
      title="Members"
      filter="brightness(65%) contrast(100%)"
    ></PageHeadingFullScreen>
    <v-container class="my-6">
      <v-row>
        <v-col cols="12" sm="6">
          <v-card
            class="mx-3"
            color="green lighten-3"
            href="https://docs.google.com/forms/d/e/1FAIpQLSeF3jzXn6GTJZSjbnwYL2gTZ171shnsCkYRpZj2X7dFOfA-eQ/viewform?usp=sf_link"
            target="_blank"
            hover
          >
            <v-card-title> Repair & Request List </v-card-title>
            <v-card-text>
              In order to help the Trustees address any issues or problems in a
              timely manner, please fill out this form when an issue arises.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card
            class="mx-3"
            color="green lighten-3"
            href="https://church-web-app-7c7fb.web.app/#/user"
            target="_blank"
            style="height: 100%"
            hover
          >
            <v-card-title> Contact List </v-card-title>
            <v-card-text>
              See one of the church leaders for more information.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import PageHeadingFullScreen from "../components/PageHeadingFullScreen.vue";

import headerImage from "./cropped-ralph-ravi-kayden-9VYBHWEoFfU-unsplash-large.webp";
import headerImageMobile from "./cropped-ralph-ravi-kayden-9VYBHWEoFfU-unsplash-mobile.webp";
import headerImageLazy from "./cropped-ralph-ravi-kayden-9VYBHWEoFfU-unsplash-lazy.webp";

export default {
  name: "Members",
  components: {
    PageHeadingFullScreen,
  },
  data() {
    return {
      headerImage,
      headerImageMobile,
      headerImageLazy,
    };
  },
};
</script>
<style lang="scss" scoped></style>
