<template>
  <div>
    <SlideShowOverlay></SlideShowOverlay>
    <div v-for="(slide, i) in activeSlides" :key="slide.src">
      <component
        :is="slide.component"
        :src="slide.src"
        :lazySrc="slide.lazySrc"
        :srcMobile="slide.srcMobile"
        :style="{ display: activeSlide == i ? 'block' : 'none' }"
        :displayed="activeSlide == i ? true : false"
        :filter="slide.filter"
        :imgClass="slide.imgClass"
      ></component>
    </div>
  </div>
</template>

<script>
import SlideShowOverlay from "./SlideShowOverlay.vue";
import PictureSlide from "./PictureSlide.vue";
import VideoSlide from "./VideoSlide.vue";

// import sanctuaryVid23 from "./media/sanctuary-master-2-3.mp4";
import sanctuaryVid480 from "./media/sanctuary-master-480.mp4";
import sanctuaryVid720 from "./media/sanctuary-master-720.mp4";

import outside480 from "./media/outside-480.mp4";
import outside720 from "./media/outside-720.mp4";

import sanctuaryPicMobile from "./media/sanctuary-pic-mobile.webp";
import sanctuaryPicMobileLazy from "./media/sanctuary-pic-lazy.webp";

export default {
  name: "HomeSlideShow",
  components: {
    PictureSlide,
    VideoSlide,
    SlideShowOverlay,
  },
  data: function () {
    return {
      activeSlide: 0,
      duration: 5000,
      slides: [
        // Sanctuary Still
        {
          breakpoints: ["xs"],
          src: sanctuaryPicMobile,
          lazySrc: sanctuaryPicMobileLazy,
          alt: "Picture of the center aisle, pointed toward the puplit.",
          component: PictureSlide,
          filter: "brightness(0.90)",
        },

        // Sanctuary video
        {
          breakpoints: ["sm"],
          src: sanctuaryVid480,
          alt: "Video of the center aisle, pointed toward the puplit, with the camera slowly rising.",
          component: VideoSlide,
          filter: "brightness(0.90)",
        },
        {
          breakpoints: ["md", "lg", "xl"],
          src: sanctuaryVid720,
          alt: "Video of the center aisle, pointed toward the puplit, with the camera slowly rising.",
          component: VideoSlide,
          filter: "brightness(0.90)",
        },

        // Outside
        {
          breakpoints: ["sm"],
          src: outside480,
          alt: "Drone video of the front of the church building.",
          component: VideoSlide,
          filter: "",
        },
        {
          breakpoints: ["md", "lg", "xl"],
          src: outside720,
          alt: "Drone video of the front of the church building.",
          component: VideoSlide,
          filter: "",
        },
      ],
    };
  },
  computed: {
    activeSlides() {
      return this.slides.filter((s) =>
        s.breakpoints.includes(this.$vuetify.breakpoint.name)
      );
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      let nextIndex = this.activeSlide + 1;
      this.activeSlide =
        nextIndex > this.activeSlides.length - 1 ? 0 : nextIndex;
    }, this.duration);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped></style>
