<template>
  <div ref="container" class="position-relative overlay-parent">
    <v-img
      v-if="srcComputed"
      :src="srcComputed"
      :lazy-src="lazySrcComputed"
      :alt="alt"
      class="background-image"
      :class="imgClass"
      contain
    ></v-img>
    <div ref="overlay" class="overlay">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "EventBase",
  props: {
    minHeight: { type: String, default: "700px" },
    maxHeight: { type: String, default: null },
    overlayWidth: { type: String, default: null },
    height: { type: String, default: "75vh" },
    imgClass: { type: Array, default: () => [] },
    src: { type: String, default: null },
    lazySrc: { type: String, default: "" },
    srcMobile: { type: String, default: "" },
    alt: { type: String, default: null },
    backgroundColor: { type: String, default: null },
  },
  computed: {
    srcComputed() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.srcMobileComputed;
        case "sm":
          return this.srcMobileComputed;
        case "md":
          return this.src;
        case "lg":
          return this.src;
        case "xl":
          return this.src;
      }
      return this.src;
    },
    srcMobileComputed() {
      return this.srcMobile ? this.srcMobile : this.src;
    },
    lazySrcComputed() {
      return this.lazySrc != "" ? this.lazySrc : this.srcMobileComputed;
    },
  },
  mounted() {
    //do something after mounting vue instance
    this.$refs.container.style.minHeight = this.minHeight;
    this.$refs.container.style.maxHeight = this.maxHeight;
    this.$refs.container.style.height = this.height;
    this.$refs.overlay.style.width = this.overlayWidth;
    this.$refs.container.style.backgroundColor = this.backgroundColor;
  },
  data: () => ({}),
};
</script>
<style lang="scss" scoped>
.overlay-parent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlay {
  position: relative;
}
.background-image {
  position: absolute;
  height: 100%;
}
.black-background {
  background-color: black;
}
.position-relative {
  position: relative;
}
</style>
