<template>
  <!-- When using v-footer, the nav drawer is truncated. This bypasses all footer style -->
  <v-sheet color="#242424" dark class="py-8">
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" lg="3" class="mb-3">
          <div class="text-body-1 font-weight-bold mb-2">Location</div>
          <div class="text-caption">ACC North Phoenix</div>
          <div class="text-caption">15651 N. 23rd Ave.</div>
          <div class="text-caption">Phoenix, AZ 85023</div>
          <div>
            <v-btn
              outlined
              left
              class="my-1"
              href="https://maps.google.com/?q=ACC%20North%20Phoenix%2015651%20N%2023rd%20Ave,%20Phoenix,%20AZ%2085023"
              target="_blank"
              small
            >
              Directions
            </v-btn>
          </div>
          <div class="text-caption mt-2">
            <a href="mailto:info@ACCNorthPhoenix.org" class="email-link"
              >info@ACCNorthPhoenix.org</a
            >
          </div>
        </v-col>
        <v-col cols="12" sm="6" lg="3" class="mb-3">
          <div class="text-body-1 font-weight-bold mb-2">Regular Services</div>
          <div class="text-caption">
            <strong>Sunday 10AM</strong>: Sunday School (kids), Bible Class
            (adults)
          </div>
          <div class="text-caption">
            <strong>Sunday 11AM</strong>: Sermon and Worship
          </div>
          <div class="text-caption">
            <strong>Wednesday 7:30PM</strong>: Discussion and Worship
          </div>
          <div>
            <v-btn
              outlined
              active-class="no-active"
              small
              :to="{ name: 'Schedule' }"
              class="mt-2"
              >See Full Schedule</v-btn
            >
          </div>
        </v-col>
        <v-col cols="12" sm="6" lg="3" class="mb-3">
          <div class="text-body-1 font-weight-bold mb-2">
            Special Events Newsletter
          </div>
          <div class="text-caption">
            Subscribe to our special events newsletter to hear about upcoming
            events (ex: VBS or Choir Programs) at ACC North Phoenix:
          </div>
          <div class="mt-4">
            <MailChimpSignup :outlined="true" :dark="true"></MailChimpSignup>
          </div>
        </v-col>
        <v-col cols="12" sm="6" lg="3" class="mb-3 d-flex justify-center">
          <div class="logo-socials">
            <img
              src="../../assets/logo-for-webpage-nav-bar.svg"
              alt=""
              class="logo darkLogo mx-auto"
            />
            <div class="d-flex justify-space-around mt-4">
              <v-btn
                dark
                x-large
                icon
                target="_blank"
                href="https://www.youtube.com/channel/UCh9sOrUGpzRDFtdz0lwljXA"
                aria-label="Youtube Channel"
              >
                <v-icon x-large>{{ mdiYoutube }}</v-icon>
              </v-btn>
              <v-btn
                dark
                x-large
                icon
                target="_blank"
                href="https://www.facebook.com/Apostolic-Christian-Church-of-North-Phoenix-105865672788872"
                aria-label="Facebook Page"
              >
                <v-icon x-large>{{ mdiFacebook }}</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
// https://stackoverflow.com/questions/57552261/vuetifyjs-adding-only-used-icons-to-build
// https://vuetifyjs.com/en/features/icon-fonts/#install-material-design-icons-js-svg
import { mdiYoutube, mdiFacebook } from "@mdi/js";
import MailChimpSignup from "./MailChimpSignup.vue";

export default {
  name: "Footer",
  components: {
    MailChimpSignup,
  },
  data() {
    return {
      mdiYoutube,
      mdiFacebook,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.logo-socials {
  width: 185px;
}
.logo {
  height: 50px;
}
.darkLogo {
  filter: grayscale(100%) brightness(1000);
}
.email-link {
  color: white;
  text-decoration: underline dotted grey;
}
</style>
